import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoTeamhero.styles'

interface Props extends PropsWithClassName {}

const LogoTeamhero = (props: Props): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'LogoTeamhero-root'}
      width="247"
      height="80"
      viewBox="0 0 270 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.17 21.962V36.1423H109.803V21.962H104.547V18.2891H119.425V21.9629L114.17 21.962Z"
        fill="#3A3A3A"
      />
      <path
        d="M120.977 36.1376V18.2188H134.154V21.8926H125.419V25.3469H132.525V28.9466H125.419V32.475H134.304V36.1414L120.977 36.1376Z"
        fill="#3A3A3A"
      />
      <path
        d="M147.851 32.9144H141.188L140.148 36.2157H135.336L142.073 18.2969H146.959L153.697 36.2157H148.88L147.851 32.9144ZM142.228 29.4572H146.743L144.449 22.2606L142.228 29.4572Z"
        fill="#3A3A3A"
      />
      <path
        d="M169.472 25.1949L165.697 33.2031H163.328L159.552 25.2681V36.1376H155.109V18.2188H159.624L164.51 28.4316L169.398 18.2188H173.913V36.1376H169.544V25.1949H169.472Z"
        fill="#3A3A3A"
      />
      <path
        d="M188.129 28.5779H181.466V36.1404H177.023V18.2216H181.466V24.9078H188.129V18.2188H192.496V36.1376H188.129V28.5779Z"
        fill="#3A3A3A"
      />
      <path
        d="M195.68 36.1376V18.2188H208.857V21.8926H200.122V25.3469H207.229V28.9466H200.122V32.475H209.008V36.1414L195.68 36.1376Z"
        fill="#3A3A3A"
      />
      <path
        d="M218.406 29.457H215.888V36.1376H211.445V18.2188H219.737C224.697 18.2188 227.139 19.9825 227.139 23.8017C227.139 26.741 225.659 28.4297 222.771 29.092L227.214 36.1451H222.476L218.406 29.457ZM219.515 21.6702H215.811V26.0796H219.438C221.285 26.0796 222.695 25.6387 222.695 23.8749C222.77 22.1121 221.364 21.6712 219.513 21.6712L219.515 21.6702Z"
        fill="#3A3A3A"
      />
      <path
        d="M246.391 27.1796C246.391 32.3226 243.873 36.4374 237.433 36.4374C231.071 36.4374 228.547 32.3968 228.547 27.1796C228.547 21.9625 231.064 17.9219 237.433 17.9219C243.801 17.9219 246.391 22.0357 246.391 27.1796ZM241.801 27.1796C241.801 23.9467 240.617 21.6698 237.358 21.6698C234.177 21.6698 232.99 23.9477 232.99 27.1796C232.99 30.4116 234.174 32.6895 237.358 32.6895C240.617 32.6876 241.801 30.4125 241.801 27.1796Z"
        fill="#3A3A3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.7592 14.6913L0.609375 52.0873L84.6331 21.083L105.659 3.52344L59.7592 14.6913Z"
        fill="#D51641"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.9381 11.8984L48.0625 22.0381L72.4184 21.0061L82.0426 13.5121L64.9381 11.8984Z"
        fill="#E9453E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.1477 6.16406L73.0078 14.616L93.3669 13.8073L101.436 7.49344L87.1477 6.16406Z"
        fill="#E8453E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.9467 10.7946L19.6328 27.841L69.7521 17.7004L82.9278 8.66406L54.9467 10.7946Z"
        fill="#EF7837"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.3662 1.17188L82.9297 8.6659L100.919 7.92663L108.026 2.34364L95.3662 1.17188Z"
        fill="#FAB63D"
      />
    </Root>
  )
}

export { LogoTeamhero }
