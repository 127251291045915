import { StaticImageData } from 'next/image'
import React, { useState } from 'react'
import Swiper, { A11y, Autoplay, Pagination } from 'swiper'
import { PaginationOptions } from 'swiper/types'

import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button'
import Container from '@Components/atoms/Container'
import { FontSizeEyebrow } from '@Components/atoms/Eyebrow/Eyebrow'
import IconArrowLeft from '@Components/atoms/Icons/IconArrowLeft'
import IconArrowRight from '@Components/atoms/Icons/IconArrowRight'
import IconLinkInternal from '@Components/atoms/Icons/IconLinkInternal'
import LogoJacques from '@Components/atoms/Logos/LogoJacques'
import LogoLekker from '@Components/atoms/Logos/LogoLekker'
import LogoTeamhero from '@Components/atoms/Logos/LogoTeamhero'
import LogoTonies from '@Components/atoms/Logos/LogoTonies'
import LogoZmg from '@Components/atoms/Logos/LogoZmg'
import Row from '@Components/atoms/Row'
import { PropsWithClassName } from '@Components/helper'
import { Routes } from '@Components/Routes'

import jacques from '@Assets/img/contents/jacques.jpg'
import lekker from '@Assets/img/contents/lekker.jpg'
import teamhero from '@Assets/img/contents/teamhero.jpg'
import tonies from '@Assets/img/contents/tonies.jpg'
import zmg from '@Assets/img/swiper/zmg.jpg'

import {
  ArrowLeft,
  ArrowRight,
  CustomPagination,
  Layer,
  Root,
  SlideButton,
  SlideContent,
  SlideLogo,
  StyledCol,
  StyledEyebrow,
  StyledHeadline,
  StyledImage,
  StyledLogo,
  StyledSwiper,
  StyledSwiperSlide,
  StyledText,
} from './CustomerSwiper.styles'

export interface CustomerSwiperSlide {
  image?: StaticImageData
  alt: string
  text: string
  buttonLink?: Routes | string | undefined
  buttonText?: string | undefined
  logo?: JSX.Element
  headline?: string
}

export const CustomerSwiperSlides: CustomerSwiperSlide[] = [
  {
    image: zmg,
    alt: 'Eine Person arbeitet an einem Notebook',
    logo: <LogoZmg />,
    text: 'ZMG-Rapid-Plattform zur Mediennutzungsforschung',
    buttonLink: Routes.services_loesungen_zmg,
    buttonText: 'Zur Success Story',
  },
  {
    image: tonies,
    alt: 'Ein Kind hört Hörspiele mit der Toniebox',
    logo: <LogoTonies />,
    text: '“Etwas Magisches” global nutzbar machen',
    buttonLink: Routes.services_loesungen_tonies,
    buttonText: 'Zur Success Story',
  },
  {
    image: jacques,
    alt: 'Weinflaschen in einem Weingeschäft',
    logo: <LogoJacques />,
    text: 'Vom Symfony Training zur Omnichannel Commerce Plattform',
    buttonLink: Routes.services_loesungen_jacques,
    buttonText: 'Zur Success Story',
  },
  {
    image: teamhero,
    alt: 'Drei Personen schauen auf einen Monitor und auf dem Schreibtisch steht eine Lampe und ein Macbook',
    logo: <LogoTeamhero />,
    text: 'Weiterentwicklung der Personalmanagement-Software',
    buttonLink: Routes.services_loesungen_teamhero,
    buttonText: 'Zur Success Story',
  },
  {
    image: lekker,
    alt: 'lekker Energie Standort Berlin bei Nacht',
    logo: <LogoLekker />,
    text: 'Symfony-Refactoring des Vertriebspartner Portals',
    buttonLink: Routes.services_loesungen_lekker,
    buttonText: 'Zur Success Story',
  },
]

interface Props extends PropsWithClassName {
  slides: CustomerSwiperSlide[]
  headline?: string
  target?: string
  qossmicSwiper?: boolean
}

const CustomerSwiper = (props: Props): React.ReactElement => {
  const [animation, setAnimation] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [swiper, setSwiper] = useState<Swiper | null>(null)

  const { slides, headline, target, qossmicSwiper } = props

  const handlePrev = (): void => {
    swiper?.slidePrev()
  }
  const handleNext = (): void => {
    swiper?.slideNext()
  }

  const pagination: PaginationOptions = {
    clickable: true,
    el: '.CustomPagination',
  }

  return (
    <Root
      id="solutions"
      className={props.className}
      data-testid={'CustomerSwiper-root'}
      target={target}
    >
      {headline && (
        <StyledEyebrow size={FontSizeEyebrow.normal}>{headline}</StyledEyebrow>
      )}
      <StyledSwiper
        modules={[A11y, Autoplay, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        speed={2000}
        autoplay={true}
        pagination={pagination}
        loop={true}
        onSwiper={(swiper) => setSwiper(swiper)}
        enabled={slides.length >= 2}
        onSlideChange={
          (swiper) =>
            setTimeout(() => {
              setActiveIndex(swiper.realIndex)
            }, 150) //  ¯\_(ツ)_/¯
        }
        onSlideChangeTransitionStart={() => setAnimation(true)}
        onSlideChangeTransitionEnd={() => setAnimation(false)}
      >
        {slides.map((slide, index) => (
          <StyledSwiperSlide target={target} key={slide.text + index}>
            {target === 'no-solution' && <Layer />}
            {slide.image && (
              <StyledImage
                width={1440}
                height={790}
                quality={85}
                src={slide.image}
                alt={slide.alt}
                loading="lazy"
              />
            )}
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
      <SlideContent target={target} animation={animation}>
        <Container>
          <Row xsCenter>
            <StyledCol
              xs={12}
              smOffset={qossmicSwiper ? 0 : 2}
              sm={10}
              mdOffset={qossmicSwiper ? 1 : 4}
              md={8}
              lgOffset={qossmicSwiper ? 0 : 5}
              lg={7}
            >
              {slides[activeIndex]?.logo && (
                <SlideLogo>{slides[activeIndex].logo}</SlideLogo>
              )}
              {slides[activeIndex]?.headline && (
                <StyledHeadline styleAs={2} importance={2}>
                  {slides[activeIndex].headline}
                </StyledHeadline>
              )}
              {slides[activeIndex]?.text && (
                <StyledText>{slides[activeIndex].text}</StyledText>
              )}
              {slides[activeIndex]?.buttonLink &&
                slides[activeIndex]?.buttonText && (
                  <SlideButton
                    icon={<IconLinkInternal />}
                    type={ButtonType.button}
                    modifier={ButtonModifier.primary}
                    href={slides[activeIndex].buttonLink}
                  >
                    {slides[activeIndex].buttonText}
                  </SlideButton>
                )}
            </StyledCol>
          </Row>
        </Container>
      </SlideContent>
      {slides.length >= 2 && <CustomPagination className="CustomPagination" />}
      <StyledLogo target={target} animation={animation} />
      {slides.length >= 2 && (
        <ArrowLeft onClick={() => handlePrev()}>
          <IconArrowLeft />
        </ArrowLeft>
      )}
      {slides.length >= 2 && (
        <ArrowRight onClick={() => handleNext()}>
          <IconArrowRight />
        </ArrowRight>
      )}
    </Root>
  )
}

export { CustomerSwiper }

CustomerSwiper.defaultProps = {
  qossmicSwiper: false,
}
