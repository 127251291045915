import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoZmg.styles'

interface Props extends PropsWithClassName {}

const LogoZmg = (props: Props): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'LogoZmg-root'}
      width="283.42"
      height="85.41"
      viewBox="0 0 500 85.41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M271.19,45.35h11.28V67c-5.87,4.42-14,6.76-21.61,6.76-16.77,0-29.11-11.51-29.11-27.59s12.34-27.59,29.34-27.59c9.37,0,17.15,3.2,22.33,9.22l-7.92,7.32a18.09,18.09,0,0,0-13.8-6c-10.37,0-17.45,6.93-17.45,17.07s7.08,17.07,17.3,17.07a19.57,19.57,0,0,0,9.6-2.28ZM223.25,72.87l-.16-53.36H213L193.29,52.67l-20-33.16H163.11V72.87h11.58V41.54l15.63,25.69h5.54l15.7-26.37.08,32ZM124.86,62.81l29.19-35.29v-8h-44.9V29.58h28.71L108.59,64.87v8h46.64V62.81Z" />
      <polygon
        points="72.91 12.51 59.07 3.24 42.71 0 55.67 11.4 72.91 12.51"
        fill="#e3e3e2"
      />
      <polygon
        points="0 42.71 3.23 59.08 12.51 72.91 11.34 55.7 0 42.71"
        fill="#00a0dd"
      />
      <polygon
        points="12.51 72.91 26.36 82.21 42.71 85.41 29.72 74.1 12.51 72.91"
        fill="#00a0dd"
      />
      <polygon
        points="12.51 12.51 3.23 26.37 0 42.71 11.39 29.73 12.51 12.51"
        fill="#e3e3e2"
      />
      <polygon
        points="42.71 0 26.36 3.24 12.51 12.51 29.74 11.4 42.71 0"
        fill="#00a0dd"
      />
      <polygon
        points="72.91 12.51 74.05 29.73 85.42 42.7 82.2 26.37 72.91 12.51"
        fill="#00a0dd"
      />
      <polygon
        points="42.71 85.41 59.07 82.21 72.91 72.91 55.72 74.09 42.71 85.41"
        fill="#ee8018"
      />
      <polygon points="72.91 72.91 82.2 59.08 85.42 42.7 74.06 55.69 72.91 72.91" />
      <polygon
        points="11.34 55.7 24.64 60.76 17.22 42.71 11.34 55.7"
        fill="#00a0dd"
      />
      <polygon
        points="11.39 29.73 17.22 42.71 24.71 24.69 11.39 29.73"
        fill="#00a0dd"
      />
      <polygon
        points="29.72 74.1 42.7 68.26 24.64 60.76 29.72 74.1"
        fill="#e3e3e2"
      />
      <polygon
        points="74.06 55.69 68.21 42.71 60.76 60.76 74.06 55.69"
        fill="#00a0dd"
      />
      <polygon
        points="55.72 74.09 60.76 60.76 42.7 68.26 55.72 74.09"
        fill="#00a0dd"
      />
      <polygon
        points="55.67 11.4 42.71 17.26 60.71 24.7 55.67 11.4"
        fill="#00a0dd"
      />
      <polygon
        points="68.21 42.71 74.05 29.73 60.71 24.7 68.21 42.71"
        fill="#ee8018"
      />
      <polygon
        points="29.74 11.41 24.71 24.69 42.7 17.26 29.74 11.41"
        fill="#00a0dd"
      />
      <polygon
        points="42.7 17.26 42.7 17.26 42.7 17.26 42.7 17.26 42.7 17.26"
        fill="#009cdf"
      />
      <polygon points="0 42.71 11.39 29.73 17.22 42.71 11.34 55.7 0 42.71" />
      <polygon points="11.39 29.73 12.51 12.51 29.74 11.4 24.71 24.69 11.39 29.73" />
      <polygon points="11.34 55.7 24.64 60.76 29.72 74.1 12.51 72.91 11.34 55.7" />
      <polygon points="29.72 74.1 42.7 68.26 55.72 74.09 42.71 85.41 29.72 74.1" />
      <polygon points="55.67 11.4 72.91 12.51 74.05 29.73 60.71 24.7 55.67 11.4" />
      <polygon
        points="42.71 0 29.74 11.4 42.71 17.26 55.67 11.4 42.71 0"
        fill="#fff"
      />
      <polygon
        points="42.71 17.26 24.71 24.69 17.22 42.71 24.64 60.76 42.7 68.26 60.76 60.76 68.21 42.71 60.71 24.7 42.71 17.26"
        fill="#fff"
      />
      <polygon
        points="74.05 29.73 68.21 42.71 74.06 55.69 85.42 42.7 74.05 29.73"
        fill="#fff"
      />
      <polygon
        points="60.76 60.76 74.06 55.69 72.91 72.91 55.72 74.09 60.76 60.76"
        fill="#fff"
      />
    </Root>
  )
}

export { LogoZmg }
